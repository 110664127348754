import type { RouteLocationNormalized, RouteRecordName } from 'vue-router'

function useDataCookie() {
  return useCookie<{
    client_id: string
    token: string
    fallback?: string
    to: {
      name?: RouteRecordName | null
      params: Record<string, any>
      query: Record<string, any>
      hash: string
    }
  } | undefined>('password-less-data')
}

export function useAuthPasswordLess() {
  const loading = ref(false)
  const error = ref('')

  const data = useDataCookie()

  function saveData(to: RouteLocationNormalized) {
    const { client_id, fallback, token } = to.query

    delete to.query.client_id
    delete to.query.fallback
    delete to.query.token

    data.value = {
      client_id: `${client_id}`,
      token: `${token}`,
      to: {
        name: to.name,
        params: to.params,
        query: to.query,
        hash: to.hash,
      },
      ...(fallback && { fallback: `${fallback}` }),
    }
  }

  const profile = useProfile()
  const refreshToken = useRefreshToken()
  const token = useToken()

  const { identify } = useAnalytic()
  const { identify: telemetryIdentify } = useTelemetry()

  async function login() {
    loading.value = true
    error.value = ''

    try {
      if (!data.value)
        throw new Error('Please use a proper link to access this page or check if your browser block the cookie `password-less-data`.')

      const response = await api.auth.passwordlessLogin({
        client_id: data.value.client_id,
        token: data.value.token,
      })

      if (!response.success) {
        if (response?.message === 'AUTH.PASSWORDLESS.CLIENT_NOT_FOUND')
          throw new ApiError(response, 'client_id not found.')
        else if (response?.message === 'AUTH.PASSWORDLESS.EXPIRED_OR_INVALID_TOKEN')
          throw new ApiError(response, 'The token is expired or invalid.')

        throw new ApiError(response)
      }

      profile.value = response.data
      token.value = response.data.access_token
      refreshToken.value = response.data.refresh_token

      identify(response.data)
      telemetryIdentify(response.data)
    }

    catch (err: any) {
      error.value = 'An error occurred'
      console.error(err)
    }

    finally {
      loading.value = false
    }
  }

  return {
    data,
    error,
    loading,
    login,
    saveData,
  }
}
