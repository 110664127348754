import validate from "/layers/google.nodejs.yarn/yarn_modules/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45authorizations_45global from "/workspace/middleware/01.authorizations.global.ts";
import _02_45layout_45global from "/workspace/middleware/02.layout.global.ts";
import manifest_45route_45rule from "/layers/google.nodejs.yarn/yarn_modules/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45authorizations_45global,
  _02_45layout_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  claims: () => import("/workspace/packages/developer/middleware/claims.ts")
}