interface AlgoliaSummary {
  objectID: string
  title: string | null
  title_fr?: string | null
  match_title?: string | null
  match_title_fr?: string | null
  developer_name: string
  icon: string
  conditions: string[]
  clinical_score: number
  global_score: number
  _highlightResult: any
}

export interface SearchAppResult {
  id: string
  title: {
    en: string
    fr: string
  }
  developerName: string
  icon: string
}

export function useNewClaimApp() {
  return useState<SearchAppResult | undefined>('dev-new-claim-app')
}

export function useNewClaimAppLock() {
  return useState('dev-new-claim-app-lock', () => false)
}

export function useSearchApp(handleAdd?: () => void) {
  const value = useNewClaimApp()
  const lock = useNewClaimAppLock()

  const open = ref(false)
  const query = ref('')
  const results = ref<SearchAppResult[]>([])
  const loading = ref(false)
  const selected = ref(0)
  const lastIndex = computed(() => Math.max(results.value.length, 0))

  function handleArrowUp() {
    if (selected.value === 0)
      selected.value = lastIndex.value
    else
      selected.value -= 1
  }

  function handleArrowDown() {
    if (selected.value === lastIndex.value)
      selected.value = 0
    else
      selected.value += 1
  }

  function handleClear() {
    open.value = false
    query.value = ''
    results.value = []
    loading.value = false
    selected.value = 0
    value.value = undefined
  }

  function handleClose() {
    open.value = false
    query.value = ''
    results.value = []
    loading.value = false
    selected.value = 0
  }

  function handleReset() {
    selected.value = 0
    results.value = []
    loading.value = false
    query.value = ''
  }

  function handleHover(index: number) {
    selected.value = index
  }

  function handleClick(index: number) {
    selected.value = index
    handlePressEnter()
  }

  function handlePressEnter() {
    if (selected.value === lastIndex.value) {
      handleAdd?.()
      handleClose()
      return
    }

    const result = results.value[selected.value]
    if (!result)
      return

    value.value = result
    handleClose()
  }

  const algolia = useAlgoliaRef()
  async function search(q: string) {
    loading.value = true

    const response = await algolia.search<AlgoliaSummary>([{
      indexName: 'summaries',
      query: q,
      params: {
        hitsPerPage: 16,
      },
    }])

    loading.value = false
    results.value = response.results[0].hits.map((hit: AlgoliaSummary) => ({
      id: hit.objectID,
      title: {
        en: hit.title || '',
        fr: hit.title_fr || hit.title || '',
      },
      developerName: hit.developer_name,
      icon: hit.icon,
    }))
  }

  watch(query, async (q) => {
    selected.value = 0

    if (!q) {
      results.value = []
      loading.value = false
      return
    }

    await search(q)
  })

  return {
    value,
    lock,
    open,
    query,
    results,
    loading,
    selected,
    lastIndex,

    handleArrowUp,
    handleArrowDown,
    handleClear,
    handleClose,
    handleReset,
    handleClick,
    handleHover,
    handlePressEnter,
  }
}
