import { $fetch } from 'ofetch'
import type { FetchOptions } from 'ofetch'

function appguide<T = AppGuide.ApiResponse>(endpoint: string, arg?: FetchOptions): Promise<T> {
  const token = useCookie('token')
  const config = useRuntimeConfig()

  return $fetch(endpoint, {
    ...arg,
    baseURL: config.public.api,
    onRequest({ options }) {
      if (!token.value)
        return

      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token.value}`,
      }
    },
  })
}

export class ApiError<T = AppGuide.ApiResponse> extends Error {
  response: T

  constructor(response: T, message?: string) {
    super(message || 'API Error Occurred')
    this.name = 'ApiError'
    this.response = response
  }
}

export const api = {
  auth: {
    profile() {
      return appguide<AppGuide.Auth.ProfileResponse>('/users/me')
    },
    login(body: { email: string; password: string }) {
      return appguide<AppGuide.Auth.LoginResponse>('/auth/login', {
        method: 'POST',
        body,
      })
    },
    signup(body: { email: string; password: string; password_confirmation: string; type: 'dev'; language: AppGuide.Locales }) {
      return appguide<AppGuide.Auth.SignupResponse>('/auth/register', {
        method: 'PUT',
        body,
      })
    },
    logout() {
      return appguide('/auth/logout')
    },
    refresh(body: { refreshToken: string }) {
      return appguide<AppGuide.Auth.RefreshResponse>('/auth/refresh', {
        method: 'POST',
        body,
      })
    },
    verifyEmail(token: string) {
      return appguide<AppGuide.Auth.VerifyEmailResponse>(`/auth/email/verify/${token}`, {
        method: 'GET',
      })
    },
    forgotPassword(email: string) {
      return appguide<AppGuide.ApiResponse>(`/auth/email/forgot-password/${email}`, {
        method: 'GET',
      })
    },
    setNewPassword(body: { newPassword: string; newPasswordToken: string }) {
      return appguide<AppGuide.ApiResponse>('/auth/email/reset-password', {
        method: 'PATCH',
        body,
      })
    },
    passwordlessLogin(params: { client_id: string; token: string }) {
      return appguide<AppGuide.Auth.PasswordLessLoginResponse>('/auth/passwordless/login', {
        method: 'GET',
        params,
      })
    },
    provider: {
      list(params: { type: 'dev'; language: AppGuide.Locales; endpoint: string }) {
        return appguide<AppGuide.Auth.ProviderListResponse>('/auth/oauth/url', {
          method: 'GET',
          params,
        })
      },
    },
  },
  users: {
    invitation: {
      accept(body: { email: string; password: string; password_confirmation: string; token: string }) {
        return appguide<AppGuide.Users.InvitationAcceptResponse>('/users/invite/accept', {
          method: 'POST',
          body,
        })
      },
    },
  },
  dev: {
    profile: {
      get() {
        return appguide<AppGuide.Devs.GetProfileResponse>('/devs/profile/get')
      },
      create(body: { first_name: string; last_name: string; linkedin_url?: string; phone_number?: string; job_title?: string; business_name?: string; business_website?: string; address?: string; address_line2?: string; city?: string; state?: string; zip?: string; country?: string }) {
        return appguide('/devs/profile/add', {
          method: 'PUT',
          body,
        })
      },
      update(body: { first_name?: string; last_name?: string; linkedin_url?: string; phone_number?: string; job_title?: string; business_name?: string; business_website?: string; address?: string; address_line2?: string; city?: string; state?: string; zip?: string; country?: string }) {
        return appguide('/devs/profile/update', {
          method: 'PATCH',
          body,
        })
      },
    },
    claims: {
      list() {
        return appguide<AppGuide.Devs.ListClaimResponse>('/devs/claims/get')
      },
      get(id: number) {
        return appguide(`/devs/claims/get/${id}`)
      },
      create(body: { summaryId: number }) {
        return appguide<AppGuide.Devs.CreateClaimResponse>('/devs/claim/add', {
          method: 'PUT',
          body,
        })
      },
      appRequest(body: { name: string; available_in_us_and_ca: 1 | 0; health_use: (0 | 1 | 2 | 3)[]; appstore_url?: string; googleplay_url?: string; webapp_url?: string; other_platform_url?: string }) {
        return appguide<AppGuide.Devs.CreateClaimResponse>('/devs/claim/add', {
          method: 'PUT',
          body,
        })
      },
      update(id: number, body: { status?: number; referral_path?: number; app_payment_methods?: number[]; app_payment_reimbursement?: number[]; lead_generation?: number[] }) {
        return appguide(`/devs/claim/update/${id}`, {
          method: 'PATCH',
          body,
        })
      },
    },
  },
  libraries: {
    library: {
      get(id: number | string) {
        return appguide(`/libraries/user/get/${id}`)
      },
      list() {
        return appguide<AppGuide.Libraries.ListResponse>('/libraries/user/all')
      },
    },
  },
}
