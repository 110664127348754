<script setup lang="ts">
const i18nHead = useLocaleHead({
  addSeoAttributes: true,
  addDirAttribute: true,
})

useHead({
  title: 'AppGuide',
  titleTemplate: (titleChunk) => {
    return (titleChunk && titleChunk !== 'AppGuide') ? `${titleChunk}` : 'AppGuide'
  },
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
  meta: [
    ...(i18nHead.value.meta || []),
  ],
})

useSchemaOrg([
  defineOrganization({
    name: 'AppGuide',
    logo: '/logo/full.png',
  }),
  defineWebSite({ name: 'AppGuide' }),
  defineWebPage(),
])

const me = useProfile()
const { identify } = useAnalytic()
const { identify: telemetryIdentify } = useTelemetry()

const initAuth = useInitAuth()
if (process.client) {
  if (me.value) {
    identify(me.value)
    telemetryIdentify(me.value)
  }

  setInterval(async () => {
    await initAuth(true)
  }, 1000 * 60 * 10)
}
</script>

<template>
  <NuxtLoadingIndicator color="#E02267" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
