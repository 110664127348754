export function useAnalytic() {
  const { $analytic } = useNuxtApp()

  return {
    track: (event: string, properties?: Record<string, any>) => $analytic.track(event, properties),
    identify: (user: AppGuide.User | AppGuide.Auth.Profile) => $analytic.identify(user),
    pageview: () => $analytic.pageview(),
    logout: () => $analytic.logout(),
  }
}
