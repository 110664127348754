import type { PostHog } from 'posthog-js'
import posthogSDK from 'posthog-js'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  const debug = Boolean(config.public.env === 'development' && config.public.debug)
  let analytic: Provider = new DebugProvider(debug)

  if (config.public.env === 'production') {
    analytic = new PosthogProvider(posthogSDK)
    analytic.init({
      apiKey: config.public.posthogKey,
      apiHost: config.public.posthogApiHost,
    })
  }

  nuxtApp.hook('page:finish', () => {
    analytic.pageview()
  })

  return {
    provide: { analytic },
  }
})

interface Provider {
  init(options: any): void

  track(event: string, properties?: Record<string, any>): void

  identify(user: AppGuide.User | AppGuide.Auth.Profile): void

  pageview(): void

  logout(): void
}

class PosthogProvider implements Provider {
  private readonly posthog: PostHog

  constructor(posthog: PostHog) {
    this.posthog = posthog
  }

  init(options: { apiKey: string; apiHost: string }): void {
    this.posthog.init(options.apiKey, { api_host: options.apiHost, capture_pageview: false })
  }

  track(event: string, properties?: Record<string, any>): void {
    this.posthog.capture(event, properties)
  }

  identify(user: AppGuide.User): void {
    this.posthog.identify(`${user.id}`)
    this.posthog.people.set({
      email: user.email,
      roleId: user.roleId,
      organizationId: user.organizationId,
    })

    if (user.organizationId) {
      this.posthog.group('organization', `${user.organizationId}`, {
        name: user.organizationName || 'Unknown',
      })
    }
  }

  pageview(): void {
    this.posthog.capture('$pageview')
  }

  logout(): void {
    this.posthog.reset()
  }
}

/* eslint-disable no-console */
class DebugProvider implements Provider {
  private readonly debug: boolean

  constructor(debug: boolean) {
    this.debug = debug
  }

  init(options: any): void {
    if (!this.debug)
      return

    console.log('[Analytics] init: ', options)
  }

  track(event: string, properties?: Record<string, any>): void {
    if (!this.debug)
      return

    console.log('[Analytics] track: ', event, properties)
  }

  identify(user: AppGuide.User): void {
    if (!this.debug)
      return

    console.log('[Analytics] identify: ', user)
  }

  pageview(): void {
    if (!this.debug)
      return

    console.log('[Analytics] pageview.')
  }

  logout(): void {
    if (!this.debug)
      return

    console.log('[Analytics] logout.')
  }
}
/* eslint-enable */
