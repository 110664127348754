import * as Sentry from '@sentry/vue'
import type { Router } from 'vue-router'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  Sentry.init({
    enabled: config.public.env === 'production',

    app: nuxtApp.vueApp,
    dsn: config.public.sentryDsn as string,
    debug: false,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', 'https://appguide.ca'],
        routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router as Router),
      }),
      // new Sentry.Replay(),
    ],

    // Performance Monitoring
    tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
})
