<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps<{
  error: NuxtError | any
}>()

const { t } = useI18n({ useScope: 'local' })
const localePath = useLocalePath()

const is404 = computed(() => props.error?.statusCode === 404 || props.error?.statusCode === '404')
const context = computed(() => props.error?.data || 'default')
const isDev = process.dev

onMounted(() => {
  setPageLayout('default')
})

const handleBackToHealthCondition = () => {
  clearError({ redirect: localePath('index') })
  handleOpenConditionsModal('error-page')
}
const handleBackToArticles = () => clearError({ redirect: localePath('resources-articles') })

const open_search = useState('global-search-open', () => false)
const handleSearch = () => {
  clearError({ redirect: localePath('health-conditions') })
  open_search.value = true
}

const i18nHead = useLocaleHead({
  addSeoAttributes: true,
  addDirAttribute: true,
})

useHead({
  title: is404.value ? t(`404.title.${context.value}`) : t('other.title.default'),
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - AppGuide` : 'AppGuide'
  },
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
  meta: [...(i18nHead.value.meta || [])],
})
</script>

<template>
  <NuxtLayout>
    <main class="flex flex-col items-center px-4 mt-16">
      <template v-if="is404">
        <img
          src="/assets/illustrations/mascot-404.svg"
          alt="AppGuide mascot holding a sign saying '404 error'"
          width="244"
          height="148"
        >

        <div class="text-center">
          <h1 class="mt-6 mb-3 text-2xl font-bold">
            Oops!
          </h1>
          <p class="text-lg font-semibold">
            {{ t(`404.title.${context}`) }}
          </p>
        </div>
      </template>

      <template v-else>
        <img
          src="/assets/illustrations/mascot-error.svg"
          alt="AppGuide mascot holding a sign saying 'error'"
          width="225"
          height="148"
        >
        <div class="text-center">
          <h1 class="mt-6 mb-3 text-2xl font-bold">
            Oops!
          </h1>
          <p class="text-lg font-semibold">
            {{ t('other.title.default') }}
          </p>
        </div>
      </template>

      <div class="mt-6">
        <template v-if="context === 'article'">
          <ul class="font-semibold text-white">
            <li>
              <button
                type="button"
                class="flex justify-center gap-2 px-8 py-4 text-center transition-colors bg-blue-500 rounded-full hover:bg-blue-600"
                @click="handleBackToArticles()"
              >
                <IconSectionsArticles class="flex-none w-6 h-6" />
                {{ t('cta.article') }}
              </button>
            </li>
          </ul>
        </template>
        <template v-else>
          <ul class="grid grid-cols-1 gap-4 font-semibold text-white md:grid-cols-2">
            <li>
              <button
                type="button"
                class="flex justify-center gap-2 px-8 py-4 text-center transition-colors bg-blue-500 rounded-full hover:bg-blue-600"
                @click="handleBackToHealthCondition()"
              >
                <IconSectionsHealthConditions class="flex-none w-6 h-6" />
                {{ t('cta.default.view-health-conditions') }}
              </button>
            </li>
            <li>
              <button
                type="button"
                class="flex justify-center w-full gap-2 px-8 py-4 transition-colors rounded-full ph-no-capture bg-violet-300 hover:bg-violet-400"
                @click="handleSearch()"
              >
                <IconSectionsSearch class="flex-none w-6 h-6" />
                <span>{{ t('cta.default.search-for-an-app') }}</span>
              </button>
            </li>
          </ul>
        </template>
      </div>

      
    </main>
  </NuxtLayout>
</template>

<style scoped lang="scss">
.dev-error-card {
  @apply w-full max-w-2xl mx-auto overflow-hidden bg-violet-900 text-violet-100 rounded-xl;
}

.js-error {
  :deep(pre) {
    @apply flex flex-col;

    .stack:not(.internal) {
      @apply bg-violet-600 font-bold block p-2;

      counter-reset: stack;

      &:not(:first-child) {
        @apply mt-3;
      }
    }

    .stack.internal {
      @apply relative bg-violet-800 pl-7 pr-2 py-1 whitespace-pre-wrap;

      &:before {
        @apply absolute left-0 bottom-0 top-0 flex justify-center pt-1 w-6 bg-violet-700;

        content: counter(stack);
        counter-increment: stack;
      }
    }
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "404": {
      "title": {
        "app-page": "App page not found",
        "summary": "App page not found",
        "article": "Article not found",
        "default": "Page not found"
      }
    },
    "other": {
      "title": {
        "default": "An error occurred"
      }
    },
    "cta": {
      "article": "Latest Articles",
      "default": {
        "view-health-conditions": "Select Health Conditions",
        "search-for-an-app": "Search for an app"
      }
    }
  },
  "fr": {
    "404": {
      "title": {
        "app-page": "Nous n'avons pas trouvé la page de l'appli que vous cherchez.",
        "summary": "Nous n'avons pas trouvé la page de l'appli que vous cherchez.",
        "article": "Nous n'avons pas trouvé l'article que vous cherchez.",
        "default": "Page introuvable"
      }
    },
    "other": {
      "title": {
        "default": "Une erreur est survenue"
      }
    },
    "cta": {
      "article": "Derniers articles",
      "default": {
        "view-health-conditions": "Voir les conditions de santé",
        "search-for-an-app": "Chercher une appli"
      }
    }
  }
}
</i18n>
