function getPageGroup(page: any) {
  const signup = ['auth___en', 'auth___fr']

  if (signup.includes(page.name?.toString()))
    return 'signup'
  else if (page.name?.toString().startsWith('auth'))
    return 'auth'
  else if (page.name?.toString().startsWith('developer'))
    return 'developer'
  else
    return 'blank'
}

export default defineNuxtRouteMiddleware((to, from) => {
  const toGroupName = getPageGroup(to)
  const fromGroupName = getPageGroup(from)

  const isAuthPage = (name: string) => ['auth', 'signup'].includes(name)
  if (isAuthPage(toGroupName) && isAuthPage(fromGroupName))
    to.meta.layoutTransition = { name: 'fade', mode: 'out-in' }

  switch (toGroupName) {
    case 'auth':
      to.meta.pageTransition = { name: 'fade', mode: 'out-in' }
      setPageLayout('auth')
      break
    case 'developer':
      to.meta.pageTransition = { name: 'fade', mode: 'out-in' }
      setPageLayout('developer')
      break
    default:
      setPageLayout('blank')
      break
  }
})
