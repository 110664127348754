export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:created', () => {
    const platform = usePlatform()
    const platformCookie = useCookie<'iOS' | 'Android'>('platform')

    if (platformCookie.value && platform.value === null)
      platform.value = platformCookie.value

    // console.log(app)
    if (platform.value === null) {
      platformCookie.value = 'iOS'
      platform.value = 'iOS'
    }
  })
})
