import type { RouteLocationNormalized } from 'vue-router'

function getPageGroup(page: RouteLocationNormalized) {
  const pageName = page.name?.toString()

  if (pageName?.startsWith('developer'))
    return 'developer'
  else if (pageName?.startsWith('pro'))
    return 'pro'
  else
    return null
}

export default defineNuxtRouteMiddleware(async (to) => {
  const localePath = useLocalePath({ route: to })

  const { client_id, token } = to.query
  const { saveData } = useAuthPasswordLess()

  if (client_id && token) {
    saveData(to)
    return navigateTo(localePath('auth-password-less'))
  }

  const initAuth = useInitAuth()
  await initAuth()

  const toGroupName = getPageGroup(to)
  if (!toGroupName)
    return

  const roleId = useProfile().value?.roleId
  if (!roleId)
    return navigateTo(localePath('auth'))

  if (roleId === 4 && toGroupName !== 'developer')
    return navigateTo(localePath('developer'))

  if (roleId === 3 && toGroupName !== 'pro')
    return navigateTo(localePath('pro'))
})
