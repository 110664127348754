import { gzipSync, strToU8 } from 'fflate'
import type { RouteLocationNormalizedLoaded } from 'vue-router'

// import { record } from 'rrweb'
// import type { listenerHandler } from '@rrweb/types'

const BLOCK_CLASS = 'rr-block'
const INIT_BASE_URL = 'https://telemetry2.appguide.ca'
const SEND_BASE_URL = 'https://telemetry.appguide.ca'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const route = useRoute()

  const telemetry = new ClientTelemetry({
    production: config.public.env === 'production',
    debug: config.public.debug,
    route,
  })

  onNuxtReady(() => {
    telemetry.init()
    telemetry.start()
  })

  nuxtApp.hook('page:finish', () => {
    telemetry.pageview()
  })

  return {
    provide: { telemetry },
  }
})

class ClientTelemetry {
  autoCapture: AutoCapture
  // rrweb: RRWeb

  private userID: number | null = null
  private trackingID: string | undefined
  private sessionID: string | undefined

  private events: (Telemetry.AutoCapture.Event | Telemetry.Events.Event)[] = []
  private records: Telemetry.RRWeb.Event[] = []
  private sendInterval: ReturnType<typeof setInterval> | undefined

  // private useBeacon = 'sendBeacon' in window.navigator
  private useBeacon = false
  private readonly production: boolean
  private readonly debug: boolean
  private readonly route?: RouteLocationNormalizedLoaded

  constructor(options: { production: boolean; debug: boolean; route: RouteLocationNormalizedLoaded }) {
    // this.rrweb = new RRWeb(e => this.records.push(e))
    this.autoCapture = new AutoCapture(data => this.pushEvent({ evt: 'capture', data }))
    this.production = options.production
    this.debug = options.debug
    this.route = options.route
  }

  public start(): void {
    this.autoCapture.startEventListeners()
    // this.rrweb.startRecording()
    this.sendInterval = setInterval(this.onInterval.bind(this), 3000)
  }

  public stop(): void {
    this.autoCapture.stopEventListeners()
    // this.rrweb.stopRecording()
    clearInterval(this.sendInterval)
  }

  async init(): Promise<void> {
    if (!this.production || !this.debug) {
      this.trackingID = 'test'
      this.sessionID = 'test'

      return
    }

    try {
      const response = await $fetch<Telemetry.TrackingIDResponse>(INIT_BASE_URL)
      this.trackingID = response.tid
      this.sessionID = response.sid
    }

    catch (e) {
      console.error(e)
    }
  }

  pageview(): void {
    const params = new URLSearchParams(window.location.search)

    let name: string | undefined
    if (this.route)
      name = String(this.route.name)

    const data: Telemetry.Events.Pageview = {
      url: window.location.toString(),
      host: window.location.host,
      pathname: window.location.pathname,
      refferer: document.referrer,
      name,

      utm_source: params.get('utm_source') ?? undefined,
      utm_medium: params.get('utm_medium') ?? undefined,
      utm_campaign: params.get('utm_campaign') ?? undefined,
      utm_term: params.get('utm_term') ?? undefined,
      utm_content: params.get('utm_content') ?? undefined,

      gclid: params.get('gclid') ?? undefined,
      fbclid: params.get('fbclid') ?? undefined,
      msclkid: params.get('msclkid') ?? undefined,
    }

    this.pushEvent({
      evt: 'pageview',
      data,
    })
  }

  track(data: Telemetry.Events.Track): void {
    this.pushEvent({
      evt: 'track',
      data,
    })
  }

  identify(user: AppGuide.User | AppGuide.Auth.Profile) {
    this.userID = user.id
    this.pushEvent({
      evt: 'identify',
      data: {
        user_id: user.id,
      },
    })
  }

  logout() {
    this.pushEvent({
      evt: 'logout',
      data: {
        user_id: this.userID,
      },
    })
    this.userID = null
  }

  private pushEvent(e: (Omit<Telemetry.AutoCapture.Event, 'now'> | Omit<Telemetry.Events.Event, 'now'>)): void {
    if (this.debug) {
      const data = {
        ...e,
        now: Date.now(),
      }

      /* eslint-disable no-console */
      console.group(`[Telemetry] ${data.evt}`)
      if (data.evt === 'capture') {
        console.log('action:', data.data.action)
        console.log('name:', data.data.name)
        console.group('context')
        console.log('page:', data.data.ctx.page)
        console.log('feature:', data.data.ctx.feature)
        console.log('section:', data.data.ctx.section)
        console.log('form:', data.data.ctx.form)
        console.groupEnd()
        console.log('params:', data.data.params)
      }

      console.groupCollapsed('JSON')
      console.log(JSON.stringify(data, null, 2))
      console.groupEnd()
      console.groupEnd()
      /* eslint-enable */

      return
    }

    if (!this.debug && !this.production)
      return

    this.events.push({
      ...e,
      now: Date.now(),
    })
  }

  private onInterval(): void {
    if (!this.trackingID || !this.sessionID)
      return

    if (this.records.length) {
      const data: Telemetry.Object = {
        t: 'records',
        tid: this.trackingID,
        sid: this.sessionID,
        evts: this.records,
        ...(this.userID ? { uid: this.userID } : {}),
      }
      this.records = []
      this.sendData(data)
    }

    if (this.events.length) {
      const data: Telemetry.Object = {
        t: 'events',
        tid: this.trackingID,
        sid: this.sessionID,
        evts: this.events,
        ...(this.userID ? { uid: this.userID } : {}),
      }
      this.events = []
      this.sendData(data)
    }
  }

  private sendData(data: any): void {
    if (!this.production)
      return

    const jsonData = JSON.stringify(data)
    const gzip = gzipSync(strToU8(jsonData), { mtime: 0 })
    const blob = new Blob([gzip], { type: 'text/plain' })

    if (this.useBeacon) {
      // window.navigator.sendBeacon(`${SEND_BASE_URL}?encoding=gzip`, blob)
      this.trySendWithBeacon(blob)
      return
    }

    $fetch(SEND_BASE_URL, {
      method: 'POST',
      headers: {
        'Content-Encoding': 'gzip',
      },
      body: blob,
    })
  }

  private trySendWithBeacon(blob: Blob): void {
    try {
      window.navigator.sendBeacon(`${SEND_BASE_URL}?encoding=gzip`, blob)
      return
    }
    catch (e) {
      this.useBeacon = false
    }

    $fetch(SEND_BASE_URL, {
      method: 'POST',
      headers: {
        'Content-Encoding': 'gzip',
      },
      body: blob,
    })
  }
}

class AutoCapture {
  private readonly vueRoute?: RouteLocationNormalizedLoaded
  onPushEvent: (e: Telemetry.AutoCapture.DataType) => void

  constructor(onPushEvent: (e: Telemetry.AutoCapture.DataType) => void) {
    this.onPushEvent = onPushEvent
    this.vueRoute = useRoute()
  }

  startEventListeners(): void {
    document.addEventListener('click', this.captureClick.bind(this), true)
    document.addEventListener('change', this.captureChange.bind(this), true)
    document.addEventListener('submit', this.captureSubmit.bind(this), true)
  }

  stopEventListeners(): void {
    document.removeEventListener('click', this.captureClick.bind(this))
    document.removeEventListener('change', this.captureChange.bind(this))
    document.removeEventListener('submit', this.captureSubmit.bind(this))
  }

  private captureClick(e: MouseEvent): void {
    const origin = this.getEventOrigin(e, 'a, button:not([type="submit"]), [x-click]')

    if (!origin)
      return

    let name = origin?.getAttribute('data-x-name')
    name ??= origin?.getAttribute('aria-label')
    name ??= origin?.textContent
    name ??= origin.tagName.toLowerCase()

    let action = origin?.getAttribute('data-x-action')
    if (origin.tagName === 'A' && !action) {
      if (origin.getAttribute('target') === '_blank')
        action ??= 'external'
      else
        action ??= 'goto'
    }
    action ??= 'click'

    this.onPushEvent({
      name,
      action,
      ctx: this.getOriginContext(origin),
      params: this.getParams(origin),
    })
  }

  private captureChange(e: Event): void {
    const origin = this.getEventOrigin(e, 'input, select, textarea') as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | null

    if (!origin)
      return

    let name = origin?.getAttribute('data-x-name')
    name ??= origin?.getAttribute('aria-label')
    name ??= origin?.name || origin?.id
    name ||= origin.tagName.toLowerCase()

    this.onPushEvent({
      name,
      action: 'change',
      ctx: this.getOriginContext(origin),
      params: this.getParams(origin),
    })
  }

  private captureSubmit(e: SubmitEvent): void {
    const origin = this.getEventOrigin(e, 'form') as HTMLFormElement | null

    if (!origin)
      return

    let name: string | null = origin?.getAttribute('name') || origin?.id
    name ??= origin?.getAttribute('data-x-name')
    name ||= origin.tagName.toLowerCase()

    this.onPushEvent({
      name,
      action: 'submit',
      ctx: this.getOriginContext(origin),
    })
  }

  private getEventOrigin(e: Event, closest: string): Element | null {
    let origin: Element | null = null

    if (e.target instanceof Element)
      origin = e.target.closest(closest)

    if (!origin)
      return null

    if (origin.closest(`.${BLOCK_CLASS}`))
      return null

    return origin
  }

  private getOriginContext(origin: Element | HTMLInputElement): Telemetry.AutoCapture.EventContext {
    const feature = origin?.closest('[data-x-feature]')?.getAttribute('data-x-feature') ?? undefined

    let form = 'form' in origin
      ? origin.form?.getAttribute('name') ?? undefined
      : undefined
    form ??= origin?.closest('form')?.getAttribute('name') ?? undefined

    let section = origin?.closest('[data-x-section]')?.getAttribute('data-x-section')
    section ??= origin?.closest('section[id]')?.id
    section ??= undefined

    let route: string | undefined
    if (this.vueRoute)
      route = String(this.vueRoute.name)

    return {
      page: route ?? window.location.pathname,
      section,
      feature,
      form,
    }
  }

  private getParams(el: Element): Record<string, string> | undefined {
    const params = new Map<string, string>()

    for (const attr of el.attributes) {
      if (!attr.name.startsWith('data-xp-'))
        continue

      params.set(
        attr.name.replace('data-xp-', ''),
        attr.value,
      )
    }

    return params.size
      ? Object.fromEntries(params)
      : undefined
  }
}

// class RRWeb {
//   private recorder: listenerHandler | undefined
//   onPushEvent: (e: Telemetry.RRWeb.Event) => void

//   constructor(onPushEvent: (e: Telemetry.RRWeb.Event) => void) {
//     this.onPushEvent = onPushEvent
//   }

//   startRecording() {
//     this.recorder = record({
//       emit: (event: Telemetry.RRWeb.Event) => {
//         this.onPushEvent(event)
//       },
//     })
//   }

//   stopRecording() {
//     if (!this.recorder)
//       return

//     this.recorder()
//     this.recorder = undefined
//   }
// }
