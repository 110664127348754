<template>
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.55545 10.7878C2.30874 10.1922 2.18176 9.55381 2.18176 8.90913C2.18176 8.26446 2.30874 7.62609 2.55545 7.03049C2.80216 6.43489 3.16377 5.89371 3.61962 5.43786C4.07548 4.982 4.61665 4.6204 5.21225 4.37369C5.80786 4.12698 6.44622 4 7.0909 4C7.73557 4 8.37394 4.12698 8.96954 4.37369C9.56514 4.6204 10.1063 4.982 10.5622 5.43786L12 6.87568L13.4378 5.43786C14.3584 4.51722 15.6071 4.00001 16.9091 4.00001C18.2111 4.00001 19.4597 4.51722 20.3804 5.43786C21.301 6.3585 21.8182 7.60715 21.8182 8.90913C21.8182 9.27917 21.7764 9.64489 21.6955 10M7.80981 16.5706L12 20.7608L14.3545 18.4062"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.18176 13.719H6.21969L8.43176 10.5725L10.9818 14.9091L13.8528 9.45459L17.1335 15.8618L19.1169 12.8956H22.909"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
