import type * as z from 'zod'

export const useClaims = () => useState<AppGuide.Devs.Claim[]>('dev-claims', () => [])
export const useClaim = () => useState<AppGuide.Devs.Claim | undefined>('dev-claim')
export const useActiveClaim = () => useCookie('dev-active-claim', { maxAge: 60 * 60 * 24 * 7 })

export async function fetchUserClaims(refresh?: boolean) {
  const claims = useClaims()
  const fetched = useState('dev-claims-fetched', () => false)

  if (fetched.value && !refresh)
    return

  try {
    const response = await api.dev.claims.list()

    if (!response.success)
      throw new ApiError(response)

    claims.value = response.data
  }
  catch (err) {
    console.error(err)
  }
  finally {
    fetched.value = true
  }
}

export const useNewClaimId = () => useState<number | undefined>('dev-new-claim-id')
export const useNewClaimStep = () => useState('dev-new-claim-step', () => 1)
export const useNewClaimStepLock = () => useState('dev-new-claim-step-lock', () => 1)
export const useNewClaimAppRequest = () => useState('dev-new-claim-app-request', () => false)
export function useNewClaimProfile() {
  return useState('dev-new-claim-step-organization-form', () => ({
    first_name: '',
    last_name: '',
    linkedin_url: '',
    phone_number: '',
    business_name: '',
    business_website: '',
    job_title: '',
    address: '',
    address_line2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  }))
}

export function useNewClaimStepSearch() {
  const claimId = useNewClaimId()
  const summaryId = ref<string>()
  const loading = ref(false)
  const error = ref('')
  const lock = useNewClaimAppLock()

  async function handleCreateClaim() {
    if (lock.value)
      return

    loading.value = true
    error.value = ''

    try {
      const response = await api.dev.claims.create({
        summaryId: Number.parseInt(summaryId.value!, 10),
      })

      if (!response.success)
        throw new ApiError(response)

      lock.value = true
      claimId.value = response.data.id
      summaryId.value = undefined
    }
    catch (err: any) {
      const response = err?.response

      if (response.message === 'DEVS.SUMMARY_ALREADY_CLAIMED') {
        error.value = 'app-already-claimed'
        return
      }

      error.value = 'unknown-error'
      console.error(err)
    }
    finally {
      loading.value = false
    }
  }

  return {
    summaryId,
    loading,
    error,
    handleCreateClaim,
  }
}

export function useNewClaimStepAppRquest() {
  const lock = useNewClaimAppLock()
  const claimId = useNewClaimId()
  const loading = ref(false)
  const error = ref('')
  const { t } = useI18n({ useScope: 'local' })

  const schema = zod.object({
    name: zod.string({ required_error: t('form.error.required') }).min(1, t('form.error.required')),
    available_in_us_and_ca: zod.union([zod.literal(0), zod.literal(1)], { errorMap: () => ({ message: t('form.error.required') }) }),
    health_use: zod.union([zod.literal(0), zod.literal(1), zod.literal(2), zod.literal(3)], { errorMap: () => ({ message: t('form.error.required') }) }).array().min(1),
    appstore_url: zod.string().optional(),
    googleplay_url: zod.string().optional(),
    webapp_url: zod.string().optional(),
    other_platform_url: zod.string().optional(),
  })
    .superRefine((obj, ctx) => {
      const urls = [obj.appstore_url, obj.googleplay_url, obj.webapp_url, obj.other_platform_url]
        .filter(url => url)
        .length

      if (urls > 0)
        return true

      for (const key of ['appstore_url', 'googleplay_url', 'webapp_url', 'other_platform_url']) {
        ctx.addIssue({
          code: zod.ZodIssueCode.custom,
          message: t('form.error.platform'),
          path: [key],
        })
      }
    })

  async function handleSubmit(data: z.infer<typeof schema>) {
    loading.value = true
    error.value = ''

    try {
      const response = await api.dev.claims.appRequest(data)

      if (!response.success)
        throw new ApiError(response)

      lock.value = true
      claimId.value = response.data.id
    }
    catch (err: any) {
      error.value = 'unknown-error'
      console.error(err)
    }
    finally {
      loading.value = false
    }
  }

  return {
    error,
    schema,
    loading,
    handleSubmit,
  }
}

export function useNewClaimStepUser() {
  const profile = useNewClaimProfile()
  const loading = ref(false)
  const error = ref('')

  const schema = zod.object({
    first_name: zod.string().min(1),
    last_name: zod.string().min(1),
    // language: zod.union([zod.literal('en'), zod.literal('fr')]),
    linkedin_url: zod.string().optional(),
    phone_number: zod.string().optional(),
  })

  async function handleSubmit(data: z.infer<typeof schema>) {
    loading.value = true
    error.value = ''

    try {
      const response = await api.dev.profile.update(data)

      if (!response.success)
        throw new ApiError(response)
    }

    catch (err: any) {
      error.value = 'unknown-error'
      console.error(err)
    }

    finally {
      loading.value = false
    }
  }

  return {
    error,
    schema,
    loading,
    profile,
    handleSubmit,
  }
}

export function useNewClaimStepOrganization() {
  const id = useNewClaimId()
  const profile = useNewClaimProfile()
  const loading = ref(false)
  const error = ref('')

  const schema = zod.object({
    business_name: zod.string().optional(),
    business_website: zod.string().optional(),
    job_title: zod.string().optional(),
    address: zod.string().optional(),
    address_line2: zod.string().optional(),
    city: zod.string().optional(),
    state: zod.string().optional(),
    zip: zod.string().optional(),
    country: zod.string().optional(),
  })

  async function refetchClaims() {
    const activeClaimId = useActiveClaim()
    try {
      await fetchUserClaims(true)
      activeClaimId.value = `${id.value}`
    }
    catch {}
  }

  async function handleSubmit(data: z.infer<typeof schema>) {
    loading.value = true
    error.value = ''

    try {
      const response = await api.dev.profile.update(data)

      if (!response.success)
        throw new ApiError(response)

      await api.dev.claims.update(id.value, {
        status: 2,
      })
      await refetchClaims()
    }

    catch (err: any) {
      error.value = 'unknown-error'
      console.error(err)
    }

    finally {
      loading.value = false
    }
  }

  async function handleSkip() {
    loading.value = true
    error.value = ''

    try {
      if (!id.value)
        throw new Error('No claim id')

      const response = await api.dev.claims.update(id.value, {
        status: 2,
      })

      if (!response.success)
        throw new ApiError(response)

      await refetchClaims()
    }

    catch (err: any) {
      error.value = 'unknown-error'
      console.error(err)
    }

    finally {
      loading.value = false
    }
  }

  return {
    error,
    schema,
    loading,
    profile,
    handleSubmit,
    handleSkip,
  }
}

export function useNewClaimStepBusinessModel() {
  const loading = ref(false)
  const error = ref('')
  const id = useNewClaimId()
  const { t } = useI18n()

  const schema = zod.object({
    app_payment_methods: zod.union([zod.literal(0), zod.literal(1), zod.literal(2)], { errorMap: () => ({ message: t('form.error.required') }) }).array().min(1),
    app_payment_reimbursement: zod
      .union([zod.literal(0), zod.literal(1), zod.literal(2), zod.literal(3)], { errorMap: () => ({ message: t('form.error.required') }) })
      .array()
      .optional(),
    referral_path: zod.union([zod.literal(0), zod.literal(1)], { errorMap: () => ({ message: t('form.error.required') }) }),
    lead_generation: zod.union([zod.literal(0), zod.literal(1), zod.literal(2), zod.literal(3), zod.literal(4)], { errorMap: () => ({ message: t('form.error.required') }) }).array().min(1),
  })
    .refine((obj) => {
      if (obj.app_payment_methods.includes(2))
        return obj.app_payment_reimbursement !== undefined && obj.app_payment_reimbursement.length > 0

      return true
    }, { message: t('form.error.required'), path: ['app_payment_reimbursement'] })

  async function refetchClaims() {
    const activeClaimId = useActiveClaim()
    try {
      await fetchUserClaims(true)
      activeClaimId.value = `${id.value}`
    }
    catch {}
  }

  async function handleSubmit(data: z.infer<typeof schema>) {
    loading.value = true
    error.value = ''

    try {
      if (!id.value)
        throw new Error('No claim id')

      const response = await api.dev.claims.update(id.value, {
        ...data,
        status: 2,
      })

      if (!response.success)
        throw new ApiError(response)

      await refetchClaims()
    }

    catch (err: any) {
      error.value = 'unknown-error'
      console.error(err)
    }

    finally {
      loading.value = false
    }
  }

  async function handleSkip() {
    loading.value = true
    error.value = ''

    try {
      if (!id.value)
        throw new Error('No claim id')

      const response = await api.dev.claims.update(id.value, {
        status: 2,
      })

      if (!response.success)
        throw new ApiError(response)

      await refetchClaims()
    }

    catch (err: any) {
      error.value = 'unknown-error'
      console.error(err)
    }

    finally {
      loading.value = false
    }
  }

  return {
    error,
    schema,
    loading,
    handleSubmit,
    handleSkip,
  }
}

export function useInitFirstClaim() {
  const id = useNewClaimId()
  const claim = useClaims().value.find(claim => claim.status === 1)
  const stepLock = useNewClaimStepLock()

  if (!claim)
    return

  id.value = claim.id
  const step = useNewClaimStep()
  const appRequest = useNewClaimAppRequest()

  if (!claim.summary || !claim.summaryId)
    appRequest.value = true

  if (!claim.user.first_name || !claim.user.last_name || !claim.user.language) {
    step.value = 2
    stepLock.value = 1
    return
  }

  stepLock.value = 2
  step.value = 3
}

export function useInitNewClaim() {
  const id = useNewClaimId()
  const claim = useClaims().value.find(claim => claim.status === 1)
  const stepLock = useNewClaimStepLock()

  if (!claim)
    return

  id.value = claim.id
  const step = useNewClaimStep()
  const appRequest = useNewClaimAppRequest()

  if (!claim.summary || !claim.summaryId)
    appRequest.value = true

  if (!claim.user.first_name || !claim.user.last_name || !claim.user.language) {
    step.value = 2
    stepLock.value = 1
    return
  }

  stepLock.value = 2
  step.value = 2
}
