import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat.js'
import relativeTime from 'dayjs/plugin/relativeTime.js'
import 'dayjs/locale/fr.js'

export default defineNuxtPlugin((nuxtApp) => {
  dayjs.locale(nuxtApp.$i18n.locale.value)
  dayjs.extend(relativeTime)
  dayjs.extend(localizedFormat)
  nuxtApp.provide('dayjs', dayjs)

  nuxtApp.$i18n.onBeforeLanguageSwitch = (_, newLocale) => {
    dayjs.locale(newLocale)
  }
})

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $dayjs(date?: dayjs.ConfigType): dayjs.Dayjs
  }
}
