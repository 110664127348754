import revive_payload_client_gnJKvI1sor from "/layers/google.nodejs.yarn/yarn_modules/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_1gtgts4dfA from "/layers/google.nodejs.yarn/yarn_modules/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_N5aviM71Ui from "/layers/google.nodejs.yarn/yarn_modules/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_iT455Rp5oG from "/layers/google.nodejs.yarn/yarn_modules/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_ClGevdFpGh from "/layers/google.nodejs.yarn/yarn_modules/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/workspace/.nuxt/components.plugin.mjs";
import prefetch_client_Mf6SAA7vFy from "/layers/google.nodejs.yarn/yarn_modules/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_4D0lfrHXLS from "/layers/google.nodejs.yarn/yarn_modules/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import i18n_wVR9w5DVRd from "/layers/google.nodejs.yarn/yarn_modules/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_wmVP2WTdXv from "/layers/google.nodejs.yarn/yarn_modules/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import analytic_client_u7SA8k4vg1 from "/workspace/plugins/analytic.client.ts";
import dayjs_pGiXRjcsJO from "/workspace/plugins/dayjs.ts";
import platform_h1J9ZTgrtG from "/workspace/plugins/platform.ts";
import sentry_client_shVUlIjFLk from "/workspace/plugins/sentry.client.ts";
import telemetry_client_zZhnIaxhvm from "/workspace/plugins/telemetry.client.ts";
import vee_validate_KcKlKmvCrJ from "/workspace/plugins/vee-validate.ts";
export default [
  revive_payload_client_gnJKvI1sor,
  unhead_1gtgts4dfA,
  router_N5aviM71Ui,
  payload_client_iT455Rp5oG,
  check_outdated_build_client_ClGevdFpGh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Mf6SAA7vFy,
  plugin_4D0lfrHXLS,
  i18n_wVR9w5DVRd,
  chunk_reload_client_wmVP2WTdXv,
  analytic_client_u7SA8k4vg1,
  dayjs_pGiXRjcsJO,
  platform_h1J9ZTgrtG,
  sentry_client_shVUlIjFLk,
  telemetry_client_zZhnIaxhvm,
  vee_validate_KcKlKmvCrJ
]