<template>
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.7222 2.50001C6.67005 2.49724 8.55001 3.23755 9.99998 4.57835C11.4499 3.23747 13.3299 2.49715 15.2778 2.50001C16.2031 2.50001 17.0916 2.66323 17.9166 2.96428V15.8859C17.069 15.5776 16.1766 15.4206 15.2778 15.4217C13.3299 15.4189 11.45 16.1592 9.99998 17.5C8.59961 16.2078 6.74975 15.4217 4.7222 15.4217C3.82337 15.4206 2.93094 15.5776 2.08331 15.8859V2.96428C2.90841 2.66323 3.79683 2.50001 4.7222 2.50001Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 4.57812V16.6664"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
